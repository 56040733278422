<template>
	<div class="toast__container" :style="styles">
		<component :is="componentName" :name="animationName" @enter="enter" @leave="leave" @after-leave="clean">
			<div v-for="item in active" class="toast__cell" :key="item.id" :data-id="item.id" :style="notifyWrapperStyle(item)">
				<slot name="body" :class="[classes, item.variant]" :item="item" :close="() => destroy(item)" >
					<!-- Default slot template --> 
					<div :class="notifyClass(item)">
						
						<div class="toast__icon">
							<template v-if="item.variant == 'success'">
								<svg version="1.1" class="toast__svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
									<g><g><path d="M504.502,75.496c-9.997-9.998-26.205-9.998-36.204,0L161.594,382.203L43.702,264.311c-9.997-9.998-26.205-9.997-36.204,0    c-9.998,9.997-9.998,26.205,0,36.203l135.994,135.992c9.994,9.997,26.214,9.99,36.204,0L504.502,111.7    C514.5,101.703,514.499,85.494,504.502,75.496z"></path></g></g>
								</svg>
							</template>
							<template v-else-if="item.variant == 'info'">
								<svg version="1.1" class="toast__svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
									<g>
										<g id="info"><g><path  d="M10,16c1.105,0,2,0.895,2,2v8c0,1.105-0.895,2-2,2H8v4h16v-4h-1.992c-1.102,0-2-0.895-2-2L20,12H8     v4H10z"></path><circle  cx="16" cy="4" r="4"></circle></g></g>
									</g>
								</svg>
							</template>
							<template v-else-if="item.variant == 'warning'">
								<svg version="1.1" class="toast__svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 301.691 301.691" style="enable-background:new 0 0 301.691 301.691;" xml:space="preserve">
									<g><polygon points="119.151,0 129.6,218.406 172.06,218.406 182.54,0  "></polygon><rect x="130.563" y="261.168" width="40.525" height="40.523"></rect></g>
								</svg>
							</template>
						</div>
						<div class="toast__content">
							<p v-if="item.title" v-html="item.title" class="toast__type"></p>
							<p v-html="item.text" class="toast__message"></p><!-- content -->
						</div>
						<div class="toast__close" @click="destroyIfNecessary(item)">
							<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.642 15.642" xmlns:xlink="http://www.w3.org/1999/xlink" enable-background="new 0 0 15.642 15.642">
								<path fill-rule="evenodd" d="M8.882,7.821l6.541-6.541c0.293-0.293,0.293-0.768,0-1.061  c-0.293-0.293-0.768-0.293-1.061,0L7.821,6.76L1.28,0.22c-0.293-0.293-0.768-0.293-1.061,0c-0.293,0.293-0.293,0.768,0,1.061  l6.541,6.541L0.22,14.362c-0.293,0.293-0.293,0.768,0,1.061c0.147,0.146,0.338,0.22,0.53,0.22s0.384-0.073,0.53-0.22l6.541-6.541  l6.541,6.541c0.147,0.146,0.338,0.22,0.53,0.22c0.192,0,0.384-0.073,0.53-0.22c0.293-0.293,0.293-0.768,0-1.061L8.882,7.821z"></path>
							</svg>
						</div>
						
						
						
					</div>
				</slot>
			</div>
		</component>
	</div> 
</template> 
<script>
	import plugin                         from './index'
	import { events }                     from './events'
	import { Id, /* split, */ listToDirection } from './util'
	import defaults                       from './defaults'
	import VelocityGroup                  from './VelocityGroup.vue'
	import CssGroup                       from './CssGroup.vue'
	import parseNumericValue              from './parser'
	const STATE = { IDLE: 0, DESTROYED: 2 }
	
	const Component = {
		name: 'Notifications',
		components: {
			VelocityGroup, CssGroup
		},
		props: {
			group: { type: String, default: '' },
			width: { type: [Number, String], default: '' },
			reverse: { type: Boolean, default: false },
			position: { type: [String, Array], default: () => { return defaults.position } },
			classes: { type: String, default: 'vue-notification' },
			animationType: { type: String, default: 'css', validator (value) { return value === 'css' || value === 'velocity' } },
			animation: { type: Object, default () { return defaults.velocityAnimation } },
			animationName: { type: String, default: defaults.cssAnimation },
			speed: { type: Number, default: 300 },
			/* Todo */ cooldown: { type: Number, default: 0 },
			duration: { type: Number, default: 3000 },
			delay: { type: Number, default: 0 },
			max: { type: Number, default: Infinity },
			ignoreDuplicates: { type: Boolean, default: false },
			closeOnClick: { type: Boolean, default: true }
		},
		data () {
			return {
				list	: [],
				velocity: plugin.params.velocity
			}
		},
		mounted () {
			events.$on('add', this.addItem);
			events.$on('close', this.closeItem);
		},
		computed: {
			actualWidth () {
				return parseNumericValue(this.width)
			},
			/** * isVelocityAnimation */ 
			isVA () {
				return this.animationType === 'velocity'
			},
			componentName () {
				return this.isVA ? 'VelocityGroup' : 'CssGroup'
			},
			styles () {
				const { x, y } = listToDirection(this.position)
				const width = this.actualWidth.value
				const suffix = this.actualWidth.type
				let styles = { width: width + suffix, [y]: '6px' } 
				if (x === 'center') {
					styles['left'] = `calc(50% - ${width/2}${suffix})`
				} else { styles[x] = '6px' }
				return styles 
			},
			active () {
				return this.list.filter(v => v.state !== STATE.DESTROYED)
			},
			botToTop () {
				// return this.styles.hasOwnProperty('bottom')
				return ''
			},
		},
		methods: {
			destroyIfNecessary (item) {
				if (this.closeOnClick) { this.destroy(item) }
			},
			addItem (event) {
				event.group = event.group || '' 
				if (this.group !== event.group) { return }
				if (event.clean || event.clear) {
					this.destroyAll()
					return 
				}
				const duration = typeof event.duration === 'number' ? event.duration : this.duration 
				const speed = typeof event.speed === 'number' ? event.speed : this.speed 
				const ignoreDuplicates = typeof event.ignoreDuplicates === 'boolean' ? event.ignoreDuplicates : this.ignoreDuplicates 
				let { title, text, variant, data, id } = event
				const item = { id: id || Id(), title, text, variant, state: STATE.IDLE, speed, length: duration + 2 * speed, data }
				if (duration >= 0) { item.timer = setTimeout(() => { this.destroy(item) }, item.length) }
				let direction = this.reverse ? !this.botToTop : this.botToTop
				let indexToDestroy = -1
				const isDuplicate = this.active.some(item => { return item.title === event.title && item.text === event.text });
				const canAdd = ignoreDuplicates ? !isDuplicate : true; 
				if (!canAdd) return;
				if (direction) { this.list.push(item)
					if (this.active.length > this.max) { indexToDestroy = 0 }
				} else {
					this.list.unshift(item) 
					if (this.active.length > this.max) { indexToDestroy = this.active.length - 1 }
				}
				if (indexToDestroy !== -1) { this.destroy(this.active[indexToDestroy]) }
			},
			closeItem (id) { this.destroyById(id) },
			notifyClass (item) {
				return [ 'custom-toast', this.classes, 'toast--' + item.variant ]
			},
			
			notifyWrapperStyle (item) {
				return this.isVA
					? null
					: { transition: `all ${item.speed}ms` }
			},
			destroy (item) {
				clearTimeout(item.timer)
				item.state = STATE.DESTROYED
				if (!this.isVA) {
					this.clean()
				}
			},
			
			destroyById (id) {
				const item = this.list.find(v => v.id === id)
				if (item) { this.destroy(item) }
			},
			
			destroyAll () { this.active.forEach(this.destroy) },
			getAnimation (index, el) {
				const animation = this.animation[index] 
				return typeof animation === 'function' ? animation.call(this, el) : animation 
			},
			enter ({ el, complete }) {
				const animation = this.getAnimation('enter', el)
				this.velocity(el, animation, { duration: this.speed, complete }) 
			},
			leave ({ el, complete }) {
				let animation = this.getAnimation('leave', el)
				this.velocity(el, animation, { duration: this.speed, complete })
			},
			clean () { this.list = this.list.filter(v => v.state !== STATE.DESTROYED) }
		} 
	}
	
	export default Component 
</script>
<style lang="scss">
	
	.toast__container {
		position: fixed;
		z-index: 10000;
		
		display: table-cell;
		vertical-align: middle;
	}
	
	.toast__cell{
		margin-bottom:12px;
		max-width: 500px;
		min-width: 400px;
	}
	
	
	.custom-toast {
		text-align:left;
		padding: 18px 0;
		background-color:#fff;
		border-radius:4px;
		top: 0px;
		position:relative;
		box-shadow: -1px 0px 7px 0px rgba(0, 0, 0, 0.2);
		
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 4px;
			height: 100%;
			border-top-left-radius:4px;
			border-bottom-left-radius: 4px;
		}
		
		.toast__icon{
			position:absolute;
			top:50%;
			left:22px;
			transform:translateY(-50%);
			width:2em;
			height:2em;
			padding: 7px;
			border-radius:50%;
			display:inline-block;
			
			.toast__svg {
				fill:#fff;
				vertical-align: text-top;
			}
		}
		
		.toast__content {
			padding-left:70px;
			padding-right:60px;
			
			.toast__type {
				color: #3e3e3e;
				font-weight: 700;
				margin-top: 0;
				margin-bottom: 2px;
			}
			
			.toast__message {
				font-size: 14px;
				margin-top: 0;
				margin-bottom: 0;
				color: #565a5c;
			}
		}
		
		.toast__close {
			position: absolute;
			right: 22px;
			top: 45%;
			width: 14px;
			cursor:pointer;
			height: 21px;
			fill:#878787;
			transform: translateY(-50%);
		}
		
		
		&.toast--success .toast__icon {
			background-color:#2BDE3F;
		}

		&.toast--success:before{
			background-color:#2BDE3F;
		}

		&.toast--info .toast__icon{
			background-color:#1D72F3;
		}

		&.toast--info:before{
			background-color:#1D72F3;
		}

		&.toast--warning .toast__icon{
			background-color:#FFC007;
		}

		&.toast--warning:before{
			background-color:#FFC007;
		}
		
	}
	
</style>
